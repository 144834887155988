<template>
  <tr style="text-align: center">
    <td v-if="p_data.subject" data-label="SUBJECT" style="overflow: hidden">
      {{ d_title }}
    </td>
    <td data-label="SUBJECT" v-else>
      <span class="text-muted">Not Specified</span>
    </td>
    <td v-if="p_role == 'tutor'" data-label="STUDENT" style="overflow: hidden">
      {{ p_data.student }}
    </td>
    <td v-else data-label="TUTOR" style="overflow: hidden">
      <span v-if="p_data.tutor">{{ p_data.tutor }}</span>
      <span v-else class="text-muted">Not Specified</span>
    </td>
    <td data-label="PURCHASE DATE">{{ d_date }}</td>
    <td data-label="STATUS">
      <span class="payment-status" :class="co_statusClass">{{
        co_statusLabel
      }}</span>
    </td>
    <td data-label="PRICE" v-if="p_role == 'student'">
      {{ p_data.price }} AED
    </td>
    <td data-label="ACTION">
      <div class="dash-action-link">
        <a v-if="d_loading" class="view">Loading</a>
        <a
          v-else-if="!p_data.subject"
          class="btn btn-remove"
          @click="m_pickSubject"
          >Pick Subject</a
        >
        <a
          v-else-if="
            p_role == 'tutor' && (p_data.status == 'A' || p_data.status == 'CD')
          "
          @click="m_askCompleteSession"
          class="btn btn-remove"
          >Complete Session</a
        >
        <a
          v-else-if="p_role == 'student' && p_data.status == 'C'"
          @click="m_askConfirmSession"
          class="btn btn-remove"
          >Confirm Session</a
        >
        <a
          v-if="p_data.subject && p_data.status == 'A'"
          @click="m_openChat"
          class="btn btn-remove"
          >Chat
          <b-modal ref="chat" :title="co_chatTitle" hide-footer :dialog-class="co_iosScaleChatDialog" centered>
            <cmp-chat
              :recipient="recipient"
              :sessionId="p_data.sessionId"
              style="height: 80vh !important"
              ref="cmpChat"
            ></cmp-chat>
          </b-modal>
          <b-badge
            id="badge-notification"
            pill
            style="background: red; color: white"
            v-if="isPending"
            >!</b-badge
          >
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { sendMail } from "@/lib-core/lib-firebase";
import { sessionStatus } from "../../lib-core/lib-firebase";
import c_chat from "../cmp-components/cmp-chat.vue";
import { database } from "@/lib-core/lib-firebase";
import { mapGetters } from "vuex";
import {ios} from 'platform-detect'
export default {
  name: "cmp-sessionrow",
  components: {
    "cmp-chat": c_chat,
  },

  props: {
    p_data: {
      type: Object,
    },
    p_role: {
      type: String,
    },
  },
  data() {
    return {
      d_date: null,
      d_loading: null,
      d_title: null,
      recipient: null,
      isPending: false,
      sentMessage: null,
    };
  },
  computed: {
    ...mapGetters("md_fire", ["gt_user"]),
    co_isIos() {
      return ios
    },
    co_iosScaleChatDialog: function () {
      return this.co_isIos ? 'iosScaleChat' : '';
    },
    co_statusLabel: function () {
      if (this.p_data == null) return "";
      switch (this.p_data.status) {
        case "A":
          return "Available";
        case "C":
          return "Pending";
        case "CA":
          return "Completed";
        case "CD":
          return "Pending(Rejected)";
        default:
          return "";
      }
    },
    co_statusClass: function () {
      if (this.p_data == null) return "";
      switch (this.p_data.status) {
        case "A":
          return "available";
        case "C":
          return "completed";
        case "CD":
          return "denied";
        case "CA":
          return "approved";
        default:
          return "";
      }
    },
    co_chatTitle: function () {
      let title = "Chat with ";
      if (this.p_role == "tutor") {
        title += this.p_data.student;
      } else {
        title += this.p_data.tutor;
      }
      return title;
    },
  },
  mounted() {
    if (!this.p_data) return;
    this.m_setRecipient();
    if (this.p_data.date) {
      this.d_date = this.$moment(this.p_data.date.toDate()).format(
        "YYYY-MM-DD"
      );
    }
    this.m_setSubjectName();
    let self = this;
    database
      .ref("chat/" + this.getchatid())
      .on("child_added", async (snapshot) => {
        if (this.p_data.subject && JSON.parse(snapshot.val()).sessionId == this.p_data.sessionId ) {
          let isPending = false;
          if (snapshot.val()) {
            let msg = JSON.parse(snapshot.val());
            if (
              msg.participantId != this.gt_user.account.email &&
              !msg.viewed
            ) {
              isPending = true;
            }
          }
          this.isPending = isPending;
        }
      });

    // setInterval(async () => {
    //   if (this.p_data.subject && this.p_data.type == "p") {
    //     let isPending = false;
    //     let ref = await database.ref("chat").child(this.getchatid());
    //     await ref.once("value", function (snapshot) {
    //       snapshot.forEach(function (child) {
    //         if (child.val()) {
    //           let msg = JSON.parse(child.val());
    //           if (
    //             msg.participantId != self.gt_user.account.email &&
    //             !msg.viewed
    //           ) {
    //             isPending = true;
    //           }
    //         }
    //       });
    //     });
    //     this.isPending = isPending;
    //   }
    // }, 10000);
  },
  methods: {
    getchatid() {
      let id = `${this.recipient}|${this.gt_user.account.email}`;
      if (this.gt_user.account.role == "student") {
        id = `${this.gt_user.account.email}|${this.recipient}`;
      }
      id = id.replaceAll(".", "-");
      return id;
    },
    async isNewMessagePending() {
      if (this.p_data.subject && this.p_data.type == "p") {
        let self = this;
        let isPending = false;
        let ref = await database.ref("chat").child(this.getchatid());
        await ref.once("value", function (snapshot) {
          snapshot.forEach(function (child) {
            if (child.val()) {
              let msg = JSON.parse(child.val());
              if (
                msg.participantId != self.gt_user.account.email &&
                !msg.viewed
              ) {
                isPending = true;
              }
            }
          });
        });
        this.isPending = isPending;
      }
    },
    async m_setSubjectName() {
      if (!this.p_data || !this.p_data.subject) return;

      const l_subject = await this.$store.dispatch(
        "md_fire/ac_getSubjectByKey",
        this.p_data.subject
      );
      this.d_title = l_subject.en;
    },
    m_pickSubject() {
      this.$router.push({ name: "subjects" });
    },
    async m_askCompleteSession() {
      this.$confirm({
        message: `Are you sure you completed this session?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: this.m_completeSession,
      });
    },
    async m_completeSession(p_answer) {
      if (!p_answer) return;
      await this.m_changeSessionStatus(
        "C",
        "Session Completed, Awaiting Student Confirmation"
      );
    },
    m_askConfirmSession() {
      this.$confirm({
        message: `Did the tutor finish the session?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: this.m_confirmSession,
      });
    },
    async m_confirmSession(p_answer) {
      let l_status = p_answer ? "CA" : "CD";
      let l_message = p_answer ? null : "Session Completion Denied";
      await this.m_changeSessionStatus(l_status, l_message);
      if (p_answer) {
        this.$emit("e_onSessionEnded", this.p_data.tutor);
      }
    },

    async m_changeSessionStatus(p_status, p_successMessage) {
      try {
        this.d_loading = true;
        const l_response = await sessionStatus({
          sessionId: this.p_data.sessionId,
          status: p_status,
        });
        if (!l_response || !l_response.data || !l_response.data.success) {
          this.m_onError(l_response);
          return;
        }
        if (p_successMessage) {
          this.$confirm({
            title: "Success",
            message: p_successMessage,
            button: {
              yes: "Ok",
            },
          });
        }
        let p_data = { ...this.p_data };
        p_data.status = p_status;
        const emailresponse = await sendMail({
          data: p_data,
          subjectName: this.d_title,
          emailevent: "SS",
        });
      } catch (p_error) {
        console.error(p_error);
        this.m_onError();
      } finally {
        this.d_loading = false;
      }
    },
    m_onError() {
      this.$confirm({
        title: "Oops",
        message: `Something went wrong. \t try again later`,
        button: {
          yes: "Ok",
        },
      });
    },
    m_setRecipient() {
      if (this.p_role == "tutor") {
        this.recipient = this.p_data.student;
      } else {
        this.recipient = this.p_data.tutor;
      }
    },
    m_openChat() {
      this.$refs["chat"].show();
      this.isPending = false;
      let chatRef = null;
      // this.$nextTick(() => {
      //   let self = this;
      //   chatRef = self.$refs.cmpChat;
      //   database.ref("chat/" + this.getchatid()).once("value", (snapshot) => {
      //     snapshot.forEach(function (child) {
      //       if (child.val() != null) chatRef.getdata(JSON.parse(child.val()));
      //     });
      //   });
      // });
    },
  },
};
</script>

<style>
.modal-body {
  padding: 0 !important;
}
.iosScaleChat {
  transform: scale(0.95) !important;
}
</style>