<template>
  <div v-if="d_show">
    <transition name="modal">
      <div class="modal-mask text-center">
        <div class="modal-wrapper text-center">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header border-none">
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click="m_closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <cmp-tutoravatar
                      :p_name="d_displayName"
                      :p_avatar="d_avatar"
                      p_size="4rem"
                    ></cmp-tutoravatar>
                  </b-col>
                </b-row>

                <p class="text-center mt-3 mb-5 text-primary">
                  <strong
                    >How satisfied are you with the Tutor
                    {{ d_displayName }} ?</strong
                  >
                </p>
                <div class="row">
                  <div class="col-4 text-center">
                    <div class="mini-container" @click="m_rate(5)">
                      <b-icon-emoji-smile
                        scale="2"
                        variant="primary"
                      ></b-icon-emoji-smile>
                      <p class="text-center">
                        <small><strong>Satisfied</strong></small>
                      </p>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <div class="mini-container" @click="m_rate(3)">
                      <b-icon-emoji-neutral
                        scale="2"
                        variant="warning"
                      ></b-icon-emoji-neutral>
                      <p class="text-center">
                        <small><strong>neutral</strong></small>
                      </p>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <div class="mini-container" @click="m_rate(1)">
                      <b-icon-emoji-angry
                        scale="2"
                        variant="danger"
                      ></b-icon-emoji-angry>
                      <p class="text-center">
                        <small><strong>unhappy</strong></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import c_tutorAvatar from "./cmp-tutoravatar.vue";
import { fb_tutorsCollection } from "@/lib-core/lib-firebase";
export default {
  name: "cmp-sessionrating",
  components: { "cmp-tutoravatar": c_tutorAvatar },
  data() {
    return {
      d_show: false,
      d_email: "",
      d_avatar: "",
      d_displayName: "",
      d_gender: "",
    };
  },
  mounted() {},
  methods: {
    async m_rate(p_value) {
      if (p_value != 1 && p_value != 3 && p_value != 5) {
        this.m_closeModal();
        return;
      }
      const l_tutorDocument = await fb_tutorsCollection.doc(this.d_email).get();

      if (l_tutorDocument.exists) {
        let l_tutorData = l_tutorDocument.data();

        let l_payload = {
          account: {
            ratingCount: l_tutorData.account.ratingCount + 1 * 1,
            ratingValue: l_tutorData.account.ratingValue + p_value * 1,
          },
        };
        await fb_tutorsCollection
          .doc(this.d_email)
          .set(l_payload, { merge: true });
      }
      this.m_closeModal();
    },
    m_closeModal() {
      this.d_show = false;
    },
    async m_showModal(p_email) {
      this.d_email = p_email;
      const l_tutorDocument = await fb_tutorsCollection.doc(this.d_email).get();
      const l_tutorData = l_tutorDocument.data();
      this.d_displayName = l_tutorData.account.displayName;
      this.d_avatar = l_tutorData.account.avatar;
      this.d_gender = l_tutorData.account.gender;
      this.d_show = true;
    },
  },
};
</script>

<style lang="css" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

html {
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(234, 199, 243) 0%,
    rgb(243, 141, 240) 100%
  );
}

head,
body {
  background: linear-gradient(
    90deg,
    rgb(234, 199, 243) 0%,
    rgb(243, 141, 240) 100%
  );
}

.container {
  display: flex;
  justify-content: center;
  margin-top: 200px;
  background: transparent;
}

.trigger {
  background-color: black;
  color: red;
}

.modal,
.fade,
.show {
  background-color: lightgray;
}

.modal-content {
  background: transparent;
  border: none;
  padding: 0 19px;
}

.modal-header {
  border: none;
}

.close {
  position: absolute !important;
  right: 0;
  z-index: 1;
  border: 7px solid lightgray !important;
  width: 5px;
  height: 5px;
  border-radius: 10rem;
  background-color: #eceff1 !important;
  opacity: 1;
  top: 35px;
  right: 25px;
}

.close > span {
  position: relative;
  right: 10px;
  padding: 0px;
  bottom: 17px;
  font-size: 29px;
  background-color: transparent;
}

.modal-body {
  border: none;
  background-color: #eceff1;
  border-radius: 8px;
  padding-bottom: 50px;
}

.modal-footer {
  border: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.close.focus,
.close:focus {
  outline: 0;
  box-shadow: none !important;
}

.fas {
  color: #faac03;
  margin-bottom: 20px;
}

.col-4 {
  padding: 0 15px;
  color: grey;
}
.mini-container {
  cursor: pointer;
}
.mini-container:hover {
  box-shadow: 0 8px 7px lightgrey;
  color: black;
}
</style>>

