<template>
  <section class="pt-0 pb-0">
    <div class="image-cover ed-detail-head invers" style="background: #fff">
      <div class="container">
        <div class="row">
          <ul>
            <cmp-sessionindicator
              p_title="Available"
              p_icon="ti-book"
              :p_count="gt_availableSessionsCount"
              p_color="#83bf6f"
            />

            <cmp-sessionindicator
              p_title="Pending"
              p_icon="ti-timer"
              :p_count="gt_pendingSessionsCount"
              p_color="#ffc478"
            />

            <cmp-sessionindicator
              p_title="Completed"
              p_icon="ti-check"
              :p_count="gt_completedSessionsCount"
              p_color="#647b9c"
            />
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 px-3">
        <div class="dashboard-container">
          <!-- <div class="dashboard-container-header">
            <div class="dashboard-fl-1">
              <h4>Sessions</h4>
            </div>
          </div> -->
          <div class="dashboard-container-body">
            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th scope="col">Subject</th>
                    <th scope="col">
                      {{
                        gt_user.account.role == "tutor" ? "Student" : "Tutor"
                      }}
                    </th>
                    <th scope="col">Purchase Date</th>
                    <th scope="col">Status</th>
                    <th scope="col" v-if="gt_user.account.role == 'student'">
                      Price
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <cmp-sessionrow
                    :p_role="gt_user.account.role"
                    :p_data="p_session"
                    v-for="p_session in gt_sessions"
                    :key="p_session.sessionId"
                    @e_onSessionEnded="m_onSessionEnded"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cmp-sessionrating ref="ref_sessionrating" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import c_sessionRow from "../cmp-components/cmp-sessionrow.vue";
import c_sessionRating from "../cmp-components/cmp-sessionrating.vue";
import c_sessionindicator from "../cmp-components/cmp-sessionindicator.vue";

export default {
  name: "cmp-sessions",
  components: {
    "cmp-sessionrow": c_sessionRow,
    "cmp-sessionrating": c_sessionRating,
    "cmp-sessionindicator": c_sessionindicator,
  },
  computed: {
    ...mapGetters("md_fire", [
      "gt_sessions",
      "gt_user",
      "gt_totalSessionsCount",
      "gt_availableSessionsCount",
      "gt_pendingSessionsCount",
      "gt_completedSessionsCount",
    ]),
  },
  mounted() {
    this.$store.commit("md_fire/mt_setDashboardSection", "sessions");
    this.$store.commit("md_fire/mt_setLoading", false);
  },
  methods: {
    m_onSessionEnded(p_tutor) {
      this.$refs.ref_sessionrating.m_showModal(p_tutor);
    },
  },
};
</script>

<style>
.main-li-box {
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  margin-right: 12px;
  box-shadow: 0px 0px 0px 3px rgb(241, 243, 247) !important;
  -webkit-box-shadow: 0px 0px 0px 3px rgb(241, 243, 247) !important;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 0px 3px #132b56 !important;
  -webkit-box-shadow: 0px 0px 0px 3px #132b56 !important;
  border: 2px solid #0d2248;
  border-radius: 50%;
  float: left;
}
.main-li-box-unlock {
  background: #0d2044 !important;
  color: #3b5b88 !important;
  border-radius: 50%;
}
.ed-detail-head {
  padding-left: 0;
}
@media (max-width: 700px) {
  .ed-detail-head {
    padding-right: 0;
    padding-left: 5px;
  }
}

.sessionText h4 {
  font-size: 35px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #22374e;
  letter-spacing: 1px;
}
.sessionText span {
  font-size: 18px;
  margin-top: 4px;
  line-height: 24px;
  font-weight: 300;
}

.color1 span {
  color: #1baf65;
}
.color2 span {
  color: #f39f00;
}
.color3 span {
  color: #ce024b;
}

::-webkit-scrollbar-thumb {
  background: none;
}
</style>