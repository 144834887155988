<template>
  <div style="display: inline">
    <li :style="c_iconStyle" class="main-li-box">
      <i :class="p_icon"></i>
    </li>
    <li :style="c_liStyle" class="main-li-text">
      <strong :style="c_textStyle">{{ p_count }}</strong>
      <span class="main-title-indicator">{{ p_title }}</span>
    </li>
  </div>
</template>

<script>
export default {
  name: "cmp-sessionindicator",
  props: ["p_title", "p_count", "p_icon", "p_color"],
  computed: {
    c_iconStyle: function () {
      return `color:${this.p_color};
        box-shadow: 0px 0px 0px 3px ${this.p_color} !important;
        -webkit-box-shadow: 0px 0px 0px 3px ${this.p_color} !important;
        border: 2px solid ${this.p_color}`;
    },

    c_liStyle: function () {
      return `color: ${this.p_color}; float: left; line-height: 34px;`;
    },

    c_textStyle: function () {
      return `color: ${this.p_color};`;
    },
  },
};
</script>

<style scoped>
.main-li-text {
  margin-right: 15px;
}

.main-title-indicator {
  font-size: inherit;
  margin-left: 4px;
}
@media (max-width: 600px) {
  .main-title-indicator {
    font-size: 10px;
    margin-left: 0px;
  }
  .main-li-text {
    margin-right: 7px;
  }
  .main-li-box {
    margin-right: 5px;
  }
}
</style>