<template>
  <div>
    <Chat
      :participants="participants"
      :myself="myself"
      :messages="messages"
      :placeholder="placeholder"
      :colors="colors"
      :border-style="borderStyle"
      :submit-icon-size="submitIconSize"
      :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null"
      :async-mode="asyncMode"
      :scroll-bottom="scrollBottom"
      :display-header="false"
      :send-images="false"
      :profile-picture-config="profilePictureConfig"
      :timestamp-config="timestampConfig"
      :link-options="linkOptions"
      @onMessageSubmit="onMessageSubmit"
      @onType="onType"
      @onClose="onClose"
    />
  </div>
</template>

<script>
import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";
import { Capacitor } from "@capacitor/core";
import { mapGetters } from "vuex";
import {
  database,
  fb_tutorsCollection,
  fb_studentsCollection,
  pushNotification,
} from "@/lib-core/lib-firebase";
import { fb_usersCollection } from "../../lib-core/lib-firebase";

let ref = null;
export default {
  name: "cmp-chat",
  components: {
    Chat,
  },
  props: {
    recipient: {
      type: String,
    },
    sessionId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("md_fire", ["gt_user"]),
    ...mapGetters("md_custom", ["gt_notifications"]),
  },
  data() {
    return {
      visible: true,
      participants: [
        {
          name: "",
          id: "",
          profilePicture: "",
        },
      ],
      myself: {
        name: "",
        id: "",
        profilePicture: "",
      },
      messages: [],
      placeholder: "send your message",
      colors: {
        header: {
          bg: "#83bf6f",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#83bf6f",
          },
          others: {
            bg: "#83bf6f",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#83bf6f",
        submitImageIcon: "#b91010",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: true,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function (e) {
              alert("Link clicked!");
            },
            mouseover: function (e) {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function (e) {
              alert("Link clicked!");
            },
            mouseover: function (e) {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  async mounted() {
    this.myself.name = this.gt_user.account.displayName;
    this.myself.id = this.gt_user.account.email;
    this.myself.profilePicture = this.gt_user.account.avatar;
    let profile = await this.m_fetchRecipientProfile();
    this.participants[0].name = profile.account.displayName;
    this.participants[0].profilePicture = profile.account.avatar;
    this.participants[0].id = profile.account.email;
    this.setViewed();
    var self = this;
    database.ref("chat/" + this.getchatid()).once("value", (snapshot) => {
      snapshot.forEach(function (child) {
        if (child.val() != null) self.getdata(JSON.parse(child.val()));
      });
    });
  },
  beforeDestroy() {
    if (!ref) return;
    ref.off();
  },
  methods: {
    async setViewed() {
      let self = this;
      let ref = database.ref("chat/" + this.getchatid());
      ref.once("value", function (snapshot) {
        snapshot.forEach(function (child) {
          if (child.val()) {
            let msg = JSON.parse(child.val());
            if (msg && msg.participantId != self.myself.id) {
              msg.viewed = true;
              let key = child.key;
              ref.update({ [child.key]: JSON.stringify(msg) });
            }
          }
        });
      });
    },
    async isNewMessagePending() {
      let self = this;
      let isPending = false;
      let ref = await database.ref("chat").child(this.getchatid());
      ref.once("value", function (snapshot) {
        snapshot.forEach(function (child) {
          if (child.val()) {
            let msg = JSON.parse(child.val());
            if (msg.participantId != self.myself.id && !msg.viewed) {
              isPending = true;
            }
          }
        });
      });
      return isPending;
    },
    onType: function (event) {
      //here you can set any behavior
    },
    loadMoreMessages(resolve) {
      setTimeout(() => {
        resolve(this.toLoad); //We end the loading state and add the messages
        //Make sure the loaded messages are also added to our local messages copy or they will be lost
        this.messages.unshift(...this.toLoad);
        this.toLoad = [];
      }, 1000);
    },
    onMessageSubmit: async function (message) {
      /*
       * example simulating an upload callback.
       * It's important to notice that even when your message wasn't send
       * yet to the server you have to add the message into the array
       */
      // this.messages.push(message);
      const token = await fb_usersCollection.doc(this.recipient).get();
      const notifications = await this.gt_notifications(this.recipient);

      if (notifications) {
        await pushNotification({
          token: token.data().token.value,
          message: message.content,
          user: this.recipient,
        });
      }
      let newMessage = Object.assign(message, { sessionId: this.sessionId });
      database.ref("chat/" + this.getchatid()).push(JSON.stringify(newMessage));
      /*
       * you can update message state after the server response
       */
      // timeout simulating the request
      setTimeout(() => {
        message.uploaded = true;
      }, 2000);
    },
    onMessageReceived() {
      let payload = {
        content: "new message",
        myself: false,
        participantId: 1,
        timestamp: "2021-04-07T23:50:21.209+04:00",
        type: "text",
        uploaded: true,
        viewed: true,
      };

      this.messages.push(payload);
    },
    onClose() {
      this.visible = false;
    },
    async m_fetchRecipientProfile() {
      try {
        let profile = null;
        if (this.gt_user.account.role == "student") {
          profile = await fb_tutorsCollection.doc(this.recipient).get();
        } else {
          profile = await fb_studentsCollection.doc(this.recipient).get();
        }
        return profile.data();
      } catch (e) {
        console.log(e);
      }
    },
    getdata(msgs) {
      if (msgs) {
        this.messages.push(msgs);
        this.$forceUpdate();
      }
    },
    getchatid() {
      let id = `${this.recipient}|${this.gt_user.account.email}`;
      if (this.gt_user.account.role == "student") {
        id = `${this.gt_user.account.email}|${this.recipient}`;
      }
      id = id.replaceAll(".", "-");
      return id;
    },
  },
};
</script>

<style></style>
